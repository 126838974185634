.App-header {
  background-color: #171123;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  animation: bgcolorchange 60s infinite;
}

.App-nav {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: rgb(255, 255, 255, 0.1);
  padding: 24px;
  text-align: center;
  letter-spacing: 2px;
}

.App-nav a {
  color: #FFF;
  text-decoration: none;
  transition: color 0.25s ease-out;
}

.App-nav a:hover {
  color: rgb(255, 255, 255, 0.9);
  mix-blend-mode: difference;
}

.App-nav span {
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  padding: 0 12px;
}

.App-link {
  color: #FFF;
}

@keyframes bgcolorchange {
  0% {
    background: #F46036;
  }

  20% {
    background: #5B85AA;
  }

  50% {
    background: #414770;
  }

  80% {
    background: #372248;
  }

  100% {
    background: #F46036;
  }
}